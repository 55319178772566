'use strict';

setTimeout( ()=> document.documentElement.classList.add('loaded'), 100 )


import './logo.mjs'


let _merge = require('lodash/merge')

import Alpine from 'alpinejs'
window.Alpine = Alpine



let ping = window.ping = ( args, args2 )=>{
    fetch( '/ping?' + new URLSearchParams({ ...(typeof args === 'string' ? {action: args} : args), ...args2, ts: Date.now() }) )
}

Alpine.directive('ping', ( el, {expression} )=>{
    el.addEventListener( 'pointerover'  , ()=> ping({ point  : expression }) )
    el.addEventListener( 'click'        , ()=> ping({ poke   : expression }) )
})

ping({ action: 'pageload', theme: docData.theme })



import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
Swiper.use([ Navigation ])


Alpine.data('swiper', ( options = {} )=>({
    swiper: null,

    init(){
        if( this.$el.querySelectorAll('.swiper-slide').length < 2 ) return

        this.swiper = new Swiper( this.$el, _merge({
            loop: true,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
        }, options) )
    }
}))


require('fslightbox')

addEventListener('DOMContentLoaded', ()=>{
    Alpine.start()

    let lightbox = new FsLightbox()
    document.querySelectorAll( '.page--instructions figure img' ).forEach(( img, i )=>{
        lightbox.props.sources.push( img.src )
        img.addEventListener( 'click', ()=>{ lightbox.open(i), ping('lightbox') } )
        img.classList.add('has-lighbox')
    })
})
