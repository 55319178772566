import { Euler, Quaternion } from 'three'

import anime from 'animejs/lib/anime.es.js'

import * as model from './logo_model.mjs'

const _debounce = require('lodash/debounce')
const _sample = require('lodash/sample')


const Quat = ( a, b, c )=> new Quaternion().setFromEuler(new Euler(
    a * .5 * Math.PI,
    b * .5 * Math.PI,
    c * .5 * Math.PI
))

const States = {
    Up      : 1,
    Left    : 2,
    Right   : 3,
    Menu    : 4,
}

const Transitions = {
    [ States.Up ]: [
        { dest: States.Up   , a: Quat(0,0,0), b: Quat( 0,1, 0) },
        { dest: States.Left , a: Quat(0,0,0), b: Quat( 1,0, 0) },
        { dest: States.Left , a: Quat(0,0,0), b: Quat(-1,0, 0) },
        { dest: States.Right, a: Quat(0,0,0), b: Quat( 0,0, 1) },
        { dest: States.Right, a: Quat(0,0,0), b: Quat( 0,0,-1) },
        { dest: States.Menu , a: Quat(0,0,0), b: Quat(0,-.5,.4), ease: 'easeOutQuart', duration: 200 },
    ],
    [ States.Left ]: [
        { dest: States.Up   , a: Quat( 1,0,0), b: Quat(0,0, 0) },
        { dest: States.Up   , a: Quat(-1,0,0), b: Quat(0,0, 0) },
        { dest: States.Right, a: Quat( 1,0,0), b: Quat(1,0, 1) },
        { dest: States.Right, a: Quat( 1,0,0), b: Quat(1,0,-1) },
        { dest: States.Left , a: Quat( 1,0,0), b: Quat(1,0, 2) },
        { dest: States.Left , a: Quat( 1,0,0), b: Quat(1,0,-2) },
    ],
    [ States.Right ]: [
        { dest: States.Up   , a: Quat(0,0,-1), b: Quat(0, 0,0) },
        { dest: States.Up   , a: Quat(0,0, 1), b: Quat(0, 0,0) },
        { dest: States.Left , a: Quat(0,0, 1), b: Quat(0, 1,1) },
        { dest: States.Left , a: Quat(0,0, 1), b: Quat(0,-1,1) },
        { dest: States.Right, a: Quat(0,0, 1), b: Quat(0, 2,1) },
        { dest: States.Right, a: Quat(0,0, 1), b: Quat(0,-2,1) },
    ],
    [ States.Menu ]: [
        //{ dest: States.Menu, a: Quat(0,-.5,.4), b: Quat(0,-.5,.4) },
        { dest: States.Up, a: Quat(0,-.5,.4), b: Quat(0,0,0) },
    ],
}

let state = States.Up
let animation = null

const transition = trans =>{
    animation && animation.pause()

    animation = anime({
        targets: { t: 0 },
        t: 1,

        easing: trans.ease || 'easeOutElastic',
        duration: trans.duration || 1000,

        update: a =>{
            const t = a.animations[0].currentValue

            model.Squares.setRotationFromQuaternion(
                new Quaternion().slerpQuaternions( trans.a, trans.b, t )
            )
        },
        begin: ()=> state = trans.dest
    })
}

const reset = _debounce(()=>{
    transition( Transitions[state][0] )
}, 1500)

document.querySelector('canvas.logomark').addEventListener('click', ()=>{
    transition(_sample( Transitions[state] ))
    reset()
})



const spinAnimation = anime({
    targets: [
        model.TopSquare.rotation,
        model.MidSquare.rotation,
        model.BotSquare.rotation,
    ],

    keyframes: [
        { y:  .5 * Math.PI },
    ],

    //direction: 'alternate',
    duration: 400,
    easing: 'easeInOutQuart',
    //loop: true,
    delay: anime.stagger(100),
    //endDelay: 1200,
})

document.querySelector('canvas.logomark').addEventListener('pointerover', ()=>{
    spinAnimation.play()
})



export default model.Logo
