
import {
    WebGLRenderer       ,
    OrthographicCamera  ,
    Scene               ,
} from 'three';
import { EffectComposer } from 'three/addons/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/addons/postprocessing/RenderPass.js';


//import LogoModel from './logo_model.mjs'
//import LogoModel from './logo_animations.mjs'
import LogoModel from './logo_machine.js'

import { DilateShaderPass, FXAAShaderPass } from './logo_shaders.mjs'

const svg = document.querySelector('svg.logomark')
const canvas = document.querySelector('canvas.logomark')
const renderer = new WebGLRenderer({ canvas })
renderer.setSize( renderer.domElement.width, renderer.domElement.width )
renderer.setPixelRatio( 2 )

const resolution = {
    x: 1 / renderer.getPixelRatio() / renderer.domElement.width  ,
    y: 1 / renderer.getPixelRatio() / renderer.domElement.height ,
}

const camera = new OrthographicCamera( -20, 20, 20, -20, .1, 100 )
camera.position.set( 20, 20, 20 )
camera.lookAt( 0, 0, 0 )


const scene = new Scene()
scene.add( LogoModel )


const composer = new EffectComposer( renderer )

composer.addPass(new RenderPass( scene, camera ))

composer.addPass( DilateShaderPass(resolution) )
composer.addPass( FXAAShaderPass(resolution) )

// import { OrbitControls } from 'three/addons/controls/OrbitControls.js';
// const controls = new OrbitControls( camera, renderer.domElement );


svg.setAttribute('hidden', true)
canvas.hidden = false

function render(){
    requestAnimationFrame( render )
    composer.render()
}

render()
