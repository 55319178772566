
import {
    BufferGeometry      ,
    DoubleSide          ,
    Group               ,
    Line                ,
    LineBasicMaterial   ,
    LineLoop            ,
    Mesh                ,
    MeshBasicMaterial   ,
    Vector3             ,
} from 'three';


const LineMaterial = new LineBasicMaterial({
    depthTest           : true      ,
    polygonOffset       : true      ,
    polygonOffsetUnits  : 2         ,
    polygonOffsetFactor : 1         ,
})
const MeshMaterial = new MeshBasicMaterial({
    color               : 0x000000          ,
    side                : DoubleSide  ,
    depthTest           : true              ,
    polygonOffset       : true              ,
    polygonOffsetUnits  : 1                 ,
    polygonOffsetFactor : 1                 ,
})


const Hex = new Line(
    new BufferGeometry().setFromPoints([
        new Vector3( -11,  11, -11 ),
        new Vector3(  11,  11, -11 ),
        new Vector3(  11, -11, -11 ),
        new Vector3(  11, -11,  11 ),
        new Vector3( -11, -11,  11 ),
        new Vector3( -11,  11,  11 ),
        new Vector3( -11,  11, -11 ),
    ]),
    LineMaterial
)


const Squares = new Group()

const Square = ( y )=> new BufferGeometry().setFromPoints([
    new Vector3( -6, y, -6 ),
    new Vector3( -6, y,  6 ),
    new Vector3(  6, y,  6 ),
    new Vector3(  6, y,  6 ),
    new Vector3(  6, y, -6 ),
    new Vector3( -6, y, -6 ),
])

const TopSquare = new Group()
const MidSquare = new Group()
const BotSquare = new Group()

TopSquare.add(new LineLoop( Square( 4), LineMaterial ))
MidSquare.add(new LineLoop( Square( 0), LineMaterial ))
BotSquare.add(new LineLoop( Square(-4), LineMaterial ))

TopSquare.add(new Mesh( Square( 4), MeshMaterial ))
MidSquare.add(new Mesh( Square( 0), MeshMaterial ))
BotSquare.add(new Mesh( Square(-4), MeshMaterial ))

Squares.add( TopSquare )
Squares.add( MidSquare )
Squares.add( BotSquare )


const Logo = new Group()

Logo.add( Hex )
Logo.add( Squares )


export default Logo
export {
    Logo,
    Hex,
    Squares,
    TopSquare,
    MidSquare,
    BotSquare,
}
