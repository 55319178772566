
import { Color } from 'three'
import { ShaderPass } from 'three/addons/postprocessing/ShaderPass.js'
import { FXAAShader } from 'three/addons/shaders/FXAAShader.js'


const DilateShaderPass = ( resolution )=> new ShaderPass({
    uniforms: {
        'tDiffuse': {},
        'resolution': { value: resolution },
        'fg': { value: new Color( 0xf9e9c8 ).convertLinearToSRGB() },
    },
    vertexShader: /* glsl */`
        varying vec2 vUv;

        void main() {
            vUv = uv;
            gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1 );
        }
    `,
    fragmentShader: /* glsl */`
        uniform sampler2D tDiffuse;
        uniform vec2 resolution;
        uniform vec3 fg;
        varying vec2 vUv;

        void main() {
            float size = 2.0;
            float opacity = 0.0;

            for( float i = -size; i <= size; ++i ){
                for( float j = -size; j <= size; ++j ){
                    opacity += .18 * (size + size - abs(i) - abs(j)) / size * texture2D( tDiffuse, vUv + resolution * vec2(i,j) ).x;
                }
            }

            if( opacity > .5 ){
                gl_FragColor = vec4( fg, 1.0 );
            }
        }
    `,
})

const FXAAShaderPass = ( resolution )=> new ShaderPass({
    uniforms: {
        'tDiffuse': {},
        'resolution': { value: resolution },
    },
    ...FXAAShader
})

export {
    DilateShaderPass,
    FXAAShaderPass,
}